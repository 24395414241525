import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Link } from "react-scroll";

import Sidebar from "../Nav/Sidebar";
import Backdrop from "../Elements/Backdrop";

import BurgerIcon from "../../assets/svg/BurgerIcon";
import Logo from "../../assets/img/logotipo.png";

export default function TopNavbar() {
  const [y, setY] = useState(window.scrollY);
  const [sidebarOpen, toggleSidebar] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => setY(window.scrollY));
    return () => {
      window.removeEventListener("scroll", () => setY(window.scrollY));
    };
  }, [y]);

  return (
    <>
      <Sidebar sidebarOpen={sidebarOpen} toggleSidebar={toggleSidebar} />
      {sidebarOpen && <Backdrop toggleSidebar={toggleSidebar} />}
      <Wrapper
        className="flexCenter animate whiteBg"
        style={y > 100 ? { height: "60px" } : { height: "80px" }}
      >
        <NavInner className="container flexSpaceCenter">
          <ImgLogo src={Logo} />

          <UlWrapperRight style={{ display: "flex", justifyContent: "end" }}>
            <BurderWrapper
              className="pointer"
              onClick={() => toggleSidebar(!sidebarOpen)}
            >
              <BurgerIcon />
            </BurderWrapper>
            <UlWrapper className="flexNullCenter">
              <li className="semiBold font18 pointer">
                <Link
                  activeClass="active"
                  style={{ padding: "10px 15px" }}
                  to="home"
                  spy={true}
                  smooth={true}
                  offset={-80}
                >
                  Home
                </Link>
              </li>
              <li className="semiBold font18 pointer">
                <Link
                  activeClass="active"
                  style={{ padding: "10px 15px" }}
                  to="pricing"
                  spy={true}
                  smooth={true}
                  offset={-80}
                >
                  Produtos
                </Link>
              </li>
              <li className="semiBold font18 pointer">
                <Link
                  activeClass="active"
                  style={{ padding: "10px 15px" }}
                  to="blog"
                  spy={true}
                  smooth={true}
                  offset={-80}
                >
                  Sobre
                </Link>
              </li>
              <li className="semiBold font18 pointer">
                <Link
                  activeClass="active"
                  style={{ padding: "10px 15px" }}
                  to="contact"
                  spy={true}
                  smooth={true}
                  offset={-120}
                >
                  Contato
                </Link>
              </li>
            </UlWrapper>
          </UlWrapperRight>
        </NavInner>
      </Wrapper>
    </>
  );
}

const Wrapper = styled.nav`
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
`;
const NavInner = styled.div`
  position: relative;
  height: 100%;
`;
const BurderWrapper = styled.button`
  outline: none;
  border: 0px;
  background-color: transparent;
  height: 100%;
  padding: 0 15px;
  display: none;
  @media (max-width: 760px) {
    display: block;
  }
`;
const UlWrapper = styled.ul`
  display: flex;
  @media (max-width: 760px) {
    display: none;
  }
`;
const UlWrapperRight = styled.ul`
  @media (max-width: 760px) {
    display: none;
  }
`;

const ImgLogo = styled.img`
  width: 20%;
  @media (max-width: 560px) {
    width: 40%;
  }
`;
