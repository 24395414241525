import React from "react";
import styled from "styled-components";
import image from "../../../src/assets/img/imgHome.png";

export default function Header() {
  return <ImgHome src={image} id="home" />;
}

const ImgHome = styled.img`
  display: table;
  width: 100%;
  height: auto;
  padding-top: 80px;
  background-color: #e5e5e5;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  background-size: cover;
  -o-background-size: cover;

  @media (max-width: 560px) {
    width: 100%;
    height: 100%;
  }
`;
