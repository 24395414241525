import React from "react";
// import Slider from "react-slick";
import styled from "styled-components";
// Assets
// import ClientLogo01 from "../../assets/img/clients/logo01.svg"
// import ClientLogo02 from "../../assets/img/clients/logo02.svg";
// import ClientLogo03 from "../../assets/img/clients/logo03.svg";
// import ClientLogo04 from "../../assets/img/clients/logo04.svg";
// import ClientLogo05 from "../../assets/img/clients/logo05.svg";
// import ClientLogo06 from "../../assets/img/clients/logo06.svg";
// import Aliro from "../../assets/img/aliro.png";
// import Allianz from "../../assets/img/allianz.png";
// import Azul from "../../assets/img/azul_seguros.png";
// import Bradesco from "../../assets/img/bradesco.png";
// import HDI from "../../assets/img/hdi.png";
// import Ituran from "../../assets/img/ituran.png";
// import Liberty from "../../assets/img/liberty.png";
// import PortoSeguro from "../../assets/img/porto-seguro.png";
// import Sompo from "../../assets/img/sompo.png";
// import Suhai from "../../assets/img/suhai.png";
// import Tokio from "../../assets/img/tokio-marine.png";
// import Zurich from "../../assets/img/zurich.webp";

// import Amil from "../../assets/img/amil.png";
// import AnaCosta from "../../assets/img/ana-costa.png";
// import BlueMed from "../../assets/img/blue_med.webp";
// import BradescoSaude from "../../assets/img/bradesco-saude.png";
// import Notredame from "../../assets/img/notredame.png";
// import SantaCasa from "../../assets/img/santa-casa.png";
// import SulAmerica from "../../assets/img/sulamerica.png";
// import Trasmontano from "../../assets/img/TRASMONTANO.png";
// import Unimed from "../../assets/img/unimed.png";
import image from "../../../src/assets/img/empresas.png";

export default function ClientSlider() {
  // const settings = {
  //   infinite: true,
  //   speed: 500,
  //   slidesToShow: 9,
  //   slidesToScroll: 2,
  //   arrows: true,
  //   responsive: [
  //     {
  //       breakpoint: 1024,
  //       settings: {
  //         slidesToShow: 4,
  //         slidesToScroll: 2,
  //       },
  //     },
  //     {
  //       breakpoint: 600,
  //       settings: {
  //         slidesToShow: 3,
  //         slidesToScroll: 1,
  //       },
  //     },
  //     {
  //       breakpoint: 480,
  //       settings: {
  //         slidesToShow: 2,
  //         slidesToScroll: 1,
  //       },
  //     },
  //   ],
  // };
  return (
  <>
    {/* <div style={{display: 'flex', alignItems: 'center'}}>
        <LogoWrapper className="flexCenter">
          <ImgStyle src={Aliro} alt="client logo" />
        </LogoWrapper>
        <LogoWrapper className="flexCenter">
          <ImgStyle src={Allianz} alt="client logo" />
        </LogoWrapper>
        <LogoWrapper className="flexCenter">
          <ImgStyle src={Bradesco} alt="client logo" />
        </LogoWrapper>
        <LogoWrapper className="flexCenter">
          <ImgStyle src={Azul} alt="client logo" />
        </LogoWrapper>
        <LogoWrapper className="flexCenter">
          <ImgStyle src={HDI} alt="client logo" />
        </LogoWrapper>
        <LogoWrapper className="flexCenter">
          <ImgStyle src={Ituran} alt="client logo" />
        </LogoWrapper>
        <LogoWrapper className="flexCenter">
          <ImgStyle src={Liberty} alt="client logo" />
        </LogoWrapper>
        <LogoWrapper className="flexCenter">
          <ImgStyle src={PortoSeguro} alt="client logo" />
        </LogoWrapper>
        <LogoWrapper className="flexCenter">
          <ImgStyle src={Sompo} alt="client logo" />
        </LogoWrapper>
        <LogoWrapper className="flexCenter">
          <ImgStyle src={Suhai} alt="client logo" />
        </LogoWrapper>
        <LogoWrapper className="flexCenter">
          <ImgStyle src={Tokio} alt="client logo" />
        </LogoWrapper>
        <LogoWrapper className="flexCenter">
          <ImgStyle src={Zurich} alt="client logo" />
        </LogoWrapper>
      </div>

      <div style={{display: 'flex', alignItems: 'center', marginTop: '50px'}}>
        <LogoWrapper className="flexCenter">
          <ImgStyle src={Amil} alt="client logo" />
        </LogoWrapper>
        <LogoWrapper className="flexCenter">
          <ImgStyle src={AnaCosta} alt="client logo" />
        </LogoWrapper>
        <LogoWrapper className="flexCenter">
          <ImgStyle src={SantaCasa} alt="client logo" />
        </LogoWrapper>
        <LogoWrapper className="flexCenter">
          <ImgStyle src={Unimed} alt="client logo" />
        </LogoWrapper>
        <LogoWrapper className="flexCenter">
          <ImgStyle src={BlueMed} alt="client logo" />
        </LogoWrapper>
        <LogoWrapper className="flexCenter">
          <ImgStyle src={BradescoSaude} alt="client logo" />
        </LogoWrapper>
        <LogoWrapper className="flexCenter">
          <ImgStyle src={Notredame} alt="client logo" />
        </LogoWrapper>
        <LogoWrapper className="flexCenter">
          <ImgStyle src={SulAmerica} alt="client logo" />
        </LogoWrapper>
        <LogoWrapper className="flexCenter">
          <ImgStyle src={Trasmontano} alt="client logo" />
        </LogoWrapper>
    </div> */}

    <ImgEmpresas src={image} id="home" />

  </>
  );
}

const ImgEmpresas = styled.img `
  display: table;
  width: 100%;
  height: auto;
  background-color: #e5e5e5;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  background-size: cover;
  -o-background-size: cover;

  @media (max-width: 560px) {
    width: 100%;
    height: auto;
  }
`
// const LogoWrapper = styled.div`
//   width: 100%;
//   height: 120px;
//   cursor: pointer;
//   :focus-visible {
//     outline: none;
//     border: 0px;
//   }
// `;

// const ImgStyle = styled.img`
//   width: 100%;
//   height: auto;
//   padding: 10%;

//   @media (max-width: 767px) {
//     width: 100%;
//   }
// `;

// const TablesWrapper = styled.div`
//   @media (max-width: 860px) {
//     flex-direction: column;
//   }
// `;

// const TableBox = styled.div`
//   width: 100%;
//   @media (max-width: 860px) {
//     width: 100%;
//     max-width: 370px;
//     margin: 0 auto
//   }
// `;
