import React from "react";
import styled from "styled-components";

export default function Blog() {
  return (
    <Wrapper id="blog">
      <div className="lightBg" style={{ padding: "50px 0" }}>
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">Sobre</h1>
            <p className="font20" style={{ marginTop: "30px" }}>
              A <b>MEGAP SEGUROS</b> é uma empresa especializada em oferecer
              soluções para manutenção do seu patrimônio e da integridade do
              indivíduo e seus familiares. Oferecemos assessoria personalizada
              em planos de <b>saúde, seguros</b> e <b>consórcios.</b>
              <br />
              <br />
              Compromisso e proatividade são nossos principais valores, por isso
              trabalhamos incansavelmente para garantir o seu bem-estar com
              tranquilidade, segurança e conforto.
              <br />
              <br />
              Nossa missão é oferecer a você sempre o melhor custo x benefício
              com transparência, responsabilidade e uma assistência contínua e
              personalizada.
            </p>
          </HeaderInfo>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
  padding-top: 20px;
`;
const HeaderInfo = styled.div`
  margin-bottom: 30px;
  @media (max-width: 860px) {
    text-align: center;
  }
`;
