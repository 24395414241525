import React from "react";
import styled from "styled-components";

import PricingTable from "../Elements/PricingTable";
import ClientSlider from "../Elements/ClientSlider";

export default function Pricing() {
  return (
    <Wrapper id="pricing">
      <div className="lightBg" style={{ padding: "50px 0" }}>
        <Empresas>
          <ClientSlider />
        </Empresas>
      </div>
      <div className="whiteBg" style={{ padding: "50px 0" }}>
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">Produtos</h1>
          </HeaderInfo>
          <TablesWrapper className="flexSpaceNull">
            <TableBox>
              <PricingTable
                price="$29,99/mo"
                title="PLANOS DE SAÚDE"
                text="Buscamos as melhores opções de plano de saúde para você, sua família e colaboradores entre as principais operadoras do mercado."
                offers={[
                  { name: "Planos individuais", cheked: true },
                  { name: "Planos familiares", cheked: true },
                  { name: "Planos empresariais", cheked: true },
                  { name: "Planos sêniores", cheked: true },
                ]}
              />
            </TableBox>
            <TableBox>
              <PricingTable
                price="$49,99/mo"
                title="SEGURO AUTO"
                text="Um novo jeito de escolher o melhor seguro para o seu veículo com cobertura básica, completa ou personalizada."
                offers={[
                  { name: "Seguro de carro", cheked: true },
                  { name: "Seguro de moto", cheked: true },
                  { name: "Seguro de taxi", cheked: true },
                ]}
              />
            </TableBox>
            <TableBox>
              <PricingTable
                price="$59,99/mo"
                title="SEGURO RESIDENCIAL"
                text="Proteção para sua residência contra imprevistos e facilidades para o seu cotidiano nos 365 dias do ano em planos variados:"
                offers={[
                  {
                    name: "Prevenção: verificação de vazamentos em registros, torneiras e válvulas de descarga",
                    cheked: true,
                  },
                  {
                    name: "Proteção: roubos, incêndios, danos elétricos",
                    cheked: true,
                  },
                  {
                    name: "Manutenção: concerto de eletrodomésticos, assistência PET e fixação de quadros",
                    cheked: true,
                  },
                ]}
              />
            </TableBox>
            <TableBox>
              <PricingTable
                price="$59,99/mo"
                title="SEGURO DE VIDA"
                text="Conte com os seguros de vida e acidentes pessoais da MegaP Seguros para garantir a tranquilidade e a segurança de quem você mais ama."
                offers={[
                  {
                    name: "Acidentes pessoais: morte acidental e invalidez total ou imparcial por acidente",
                    cheked: true,
                  },
                  { name: "Auxílio funeral", cheked: true },
                  { name: "Inclusão de cônjuge ou companheiro", cheked: true },
                ]}
              />
            </TableBox>
          </TablesWrapper>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
  padding: 50px 0;
`;
const HeaderInfo = styled.div`
  margin-bottom: 50px;
  @media (max-width: 860px) {
    text-align: center;
  }
`;
const TablesWrapper = styled.div`
  @media (max-width: 860px) {
    flex-direction: column;
  }
`;
const TableBox = styled.div`
  width: 31%;
  @media (max-width: 860px) {
    width: 100%;
    max-width: 370px;
    margin: 0 auto;
  }
`;

const Empresas = styled.div`
  width: 100%;
  margin: 0 auto;
  padding: 0px 30px;
`;
