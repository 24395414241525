import React from "react";
import styled from "styled-components";
import Logo from "../../assets/img/blue-logo.png";

export default function Contact() {
  return (
    <Wrapper>
      <div
        className="darkBg"
        style={{ padding: "50px 0 0 0", marginTop: "70px" }}
      >
        <div className="container">
          <InnerWrapper>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <ImgLogo src={Logo} />
            </div>
          </InnerWrapper>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
`;
const InnerWrapper = styled.div`
  @media (max-width: 550px) {
    width: 100%;
    justify-content: center;
  }
`;

const ImgLogo = styled.img`
  margin-bottom: 50px;
  width: 25%;
  @media (max-width: 560px) {
    width: 50%;
    height: auto;
  }
`;
