import React from "react";

import TopNavbar from "../components/Nav/TopNavbar";
import Header from "../components/Sections/Header";
import Blog from "../components/Sections/Blog";
import Pricing from "../components/Sections/Pricing";
import Contact from "../components/Sections/Contact";
import Footer from "../components/Sections/Footer";

export default function Landing() {
  return (
    <>
      <TopNavbar />
      <Header />
      <Pricing />
      <Blog />
      <Contact />
      <Footer />

      <a
        href="https://api.whatsapp.com/send?phone=5511988084503"
        className="whatsapp-button"
        target="_blank"
        rel="noreferrer"
        style={{ position: "fixed", right: "30px", bottom: "30px" }}
      >
        <img
          src="https://i.ibb.co/VgSspjY/whatsapp-button.png"
          alt="botão whatsapp"
        />
      </a>
    </>
  );
}
