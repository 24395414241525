import React, { useState } from "react";
import styled from "styled-components";
import Alert from "@mui/material/Alert";
import emailjs from "emailjs-com";

import LogoInsta from "../../assets/img/instagram.png";
import LogoWhats from "../../assets/img/whatsapp.png";
import LogoFace from "../../assets/img/facebook.png";

const initialState = {
  name: "",
  email: "",
  message: "",
};

export default function Contact() {
  const [{ name, email, message }, setState] = useState(initialState);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  };

  const clearState = () => setState({ ...initialState });

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(name, email, message);
    emailjs
      .sendForm(
        "email_megap",
        "template_hu0d7zk",
        e.target,
        "SBQZrTDwAGWngUdsf"
      )
      .then(
        (result) => {
          <Alert variant="filled" severity="success">
            Mensagem enviada com sucesso!!
          </Alert>;
          alert("Mensagem enviada com sucesso!!");
          console.log(result.text);
          clearState();
        },
        (error) => {
          <Alert variant="filled" severity="error">
            {error.message}
          </Alert>;
          alert(error.message);
          console.log(error.text);
        }
      );

    e.target.reset();
  };
  return (
    <Wrapper id="contact">
      <div>
        <div className="container">
          <h1 className="font40 extraBold" style={{ marginTop: "80px" }}>
            Contato
          </h1>
          <div
            className="row"
            style={{ paddingBottom: "30px", paddingTop: "40px" }}
          >
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
              <Wrapper
                className="whiteBg radius8 shadow"
                style={{ padding: "30px" }}
              >
                <div>
                  <div style={{ margin: "15px 0" }}>
                    <div
                      style={{
                        position: "relative",
                        top: "-1px",
                        marginRight: "15px",
                      }}
                    >
                      <div style={{ minWidth: "20px" }}>
                        {/* <ContactImg1 /> */}
                      </div>
                    </div>
                    <p className="font17 extraBold">Localização</p>
                    <p className="font13">
                      Rua Vital Brasil, 40, Vila Belmiro - Santos, SP -
                      11070-190
                    </p>

                    <br />

                    <p className="font17 extraBold">E-mail</p>
                    <p className="font13">megap@megapseguros.com</p>

                    <br />

                    <p className="font17 extraBold">Tel</p>
                    <p className="font13">(13) 3014-9220</p>

                    <br />

                    <p className="font17 extraBold">Cel</p>
                    <p className="font13">(13) 98117-9239</p>
                    <p className="font13">(11) 98808-4503</p>

                    <br />

                    <p className="font17 extraBold">Horário de atendimento</p>
                    <p className="font13">Segunda a Sexta - 9:00 às 18:00</p>

                    <br />
                    <br />

                    <div className="flexSpaceCenter" style={{ width: "50%" }}>
                      <a
                        href="https://www.facebook.com/megapseguros"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <SocialMedia src={LogoFace} />
                      </a>
                      <a
                        href="https://www.instagram.com/megapseguros/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <SocialMedia src={LogoInsta} />
                      </a>
                      <a
                        href="https://api.whatsapp.com/send?phone=5511988084503"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <SocialMedia src={LogoWhats} />
                      </a>
                    </div>
                  </div>
                </div>
              </Wrapper>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
              <h1 className="font30 extraBold" style={{ marginTop: "25px" }}>
                Deixe sua mensagem
              </h1>
              <Form name="sentMessage" validate onSubmit={handleSubmit}>
                <label className="font13">Nome:</label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  className="font18 extraBold"
                  required
                  onChange={handleChange}
                />
                <label className="font13">Email:</label>
                <input
                  type="text"
                  id="email"
                  name="email"
                  className="font18 extraBold"
                  required
                  onChange={handleChange}
                />
                <label className="font13">Mensagem:</label>
                <textarea
                  rows="4"
                  cols="50"
                  type="text"
                  id="message"
                  name="message"
                  className="font18 extraBold"
                  required
                  onChange={handleChange}
                />
                <SumbitWrapper className="flex">
                  <ButtonInput
                    type="submit"
                    value="Enviar"
                    className="pointer animate radius8 font15 semiBold"
                    style={{ maxWidth: "220px" }}
                  />
                </SumbitWrapper>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
`;

const Form = styled.form`
  padding: 20px 0 30px 0;
  input,
  textarea {
    width: 100%;
    border: 0px;
    outline: none;
    box-shadow: none;
    border-bottom: 1px solid #707070;
    height: auto;
    margin-bottom: 30px;
  }
  textarea {
    min-height: 100px;
  }
  @media (max-width: 860px) {
    padding: 30px 0;
  }
`;
const ButtonInput = styled.input`
  border: 1px solid #f10444;
  background-color: #f10444;
  width: 100%;
  padding: 15px;
  outline: none;
  color: #fff;
  :hover {
    background-color: #ce0037;
    border: 1px solid #f10444;
    color: #fff;
  }
  @media (max-width: 991px) {
    margin: 0 auto;
  }
`;

const SumbitWrapper = styled.div`
  @media (max-width: 991px) {
    width: 100%;
    margin-bottom: 50px;
  }
`;

const SocialMedia = styled.img`
  width: 35%;
  @media (max-width: 560px) {
    width: 35%;
    height: auto;
  }
`;
